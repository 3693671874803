<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit"
    >
      <div slot="content">
        <a-card title="会员充值">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 14 }"
          >
            <a-row>
              <a-col :span="12">
                <a-row>
                  <a-col>
                    <a-form-model-item label="会员卡号" prop="mobile">
                      <a-input-search
                        placeholder="请输入会员卡号/手机号"
                        allowClear
                        v-model.trim="form.mobile"
                        :disabled="isDetail"
                        @change="handleSearch"
                        @pressEnter="handleSearch"
                      >
                      </a-input-search>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col>
                    <a-form-model-item label="次卡名称" prop="timesCardId">
                      <a-select
                        v-model="form.timesCardId"
                        :disabled="isDetail"
                        allowClear
                        @change="handleChangeTimesCardType"
                      >
                        <a-select-option
                          v-for="item in timesCardTypeList"
                          :key="item.timesCardId"
                        >{{ item.timesCardName }}</a-select-option
                        >
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col>
                    <a-form-model-item label="备注" prop="remarks">
                      <a-input
                        v-model="form.remarks"
                        :auto-size="{ minRows: 4, maxRows: 6 }"
                        allowClear
                        :maxLength="100"
                        type="textarea"
                        :disabled="isDetail"
                      ></a-input>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col>
                    <ul class="goods__list">
                      <section v-for="item in timesCardRuleList" :key="item.timesCardRuleId">
                        <li
                          class="goods__item"
                        >
                          <div
                            class="couponCardItem"
                            @click="handleChooseTimesCardRule(item)"
                          >
                            <div class="yuan"></div>
                            <div class="left">
                              <div class="l-inner">
                                <div class="l1">
                                  ￥<span style="font-weight: bold; line-height: 1">{{
                                    item.price
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="right">
                              <div style="display: flex; flex-direction: row">
                                <div class="Title_name">{{item.timesCardName}}</div>
                              </div>
                              <div>{{item.kartClassifyName}}</div>
                              <div class="indate">{{item.count}}</div>
                            </div>
                            <div class="checked-ouline" v-if="!!item.checked">
                              <a-icon type="check-circle" />
                            </div>
                          </div>
                        </li>
                      </section>
                    </ul>
                  </a-col>
                </a-row>
              </a-col>
              <a-col span="10" push="1" v-if="!paramsId">
                <a-descriptions title="会员信息" :column="1">
                  <a-descriptions-item label="姓名">{{
                    currentMember.realName || "-"
                  }}</a-descriptions-item>
                  <a-descriptions-item label="手机号">{{
                    currentMember.mobile || "-"
                  }}</a-descriptions-item>
                  <a-descriptions-item label="会员卡号">
                    {{ currentMember.memberCode || "-" }}</a-descriptions-item
                  >
                  <a-descriptions-item label="当前余额">
                    {{ currentMember.amount || "0" }} 元</a-descriptions-item
                  >
                </a-descriptions>
                <div v-if="timesCardPersonList.length > 0">
                  <div>充值前次数</div>
                  <div v-for="item in timesCardPersonList"
                       :key="item.timesCardPersonId">
                    <span>{{item.kartClassifyName}}：</span><span>{{item.count}}次</span>
                  </div>
                </div>

                <div v-if="timesCardPersonRechargeList.length > 0">
                  <div>充值后次数</div>
                  <div v-for="item in timesCardPersonRechargeList"
                       :key="item.timesCardPersonId">
                    <span>{{item.kartClassifyName}}：</span><span>{{item.count}}次</span>
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>

    <!-- 收款弹出窗 开始 -->
    <payment-modal
      v-model="payVisible"
      :data="form"
      @on-ok="handlePayOk"
      @on-cancel="handleCancel"
    ></payment-modal>
    <!-- 收款弹出窗 结束 -->
  </div>
</template>

<script>
import { timesCardList, timesCardRuleUsableList, timesCardPersonList,timesCardRechargeSubmit } from '@/api/timesCard';
import { getByCodeOrMobile } from '@/api/member';
import FormFrame from '@/components/frame/FormFrame.vue';
import PaymentModal from '@/components/PaymentModal.vue';
import formMixin from '@/mixin/formMixin';
import { deepClone } from '@/libs/utils';

export default {
  name: 'TimesCardRechargeForm',
  mixins: [formMixin],
  components: {
    FormFrame,
    PaymentModal,
  },
  props: {},
  data() {
    return {
      timesCardTypeList: [],
      selectTimesCardRuleList: [],
      timesCardRuleList: [],
      timesCardPersonList: [],
      timesCardPersonRechargeList: [],
      currentMember: {},
      payVisible: false,
      paymentMap: new Map([
        [1, '微信'],
        [2, '支付宝'],
        [3, '现金'],
        [4, '银联'],
      ]),
      formCopy: {},
      form: {
        amount: 0,
        payType: 1,
        remarks: '',
      },
      rules: {
        mobile: [
          {
            required: true,
            message: '请输入',
          },
        ],
        timesCardId: [
          {
            required: true,
            message: '请选择次卡类型'
          }
        ],
      },
    };
  },
  watch: {},
  created() {
    this.getTimesCardTypeList();
  },
  methods: {
    // getData(id) {
    //   rechargeInfo(id).then((res) => {
    //     this.form = res;
    //   });
    // },
    getTimesCardTypeList() {
      timesCardList({ status: 0 }).then((data) => {
        this.timesCardTypeList = data;
        console.log(this.timesCardTypeList);
      });
    },
    handleReset() {
      this.$refs.form.resetFields();
    },
    handleChangeTimesCardType(timesCardId) {
      if (this.currentMember.memberId) {
        console.log('会员存在');
        this.timesCardPersonRechargeList = [];
        this.selectTimesCardRuleList = [];
        if (timesCardId) {
          timesCardRuleUsableList(timesCardId).then((data) => {
            this.timesCardRuleList = data;
            console.log(this.timesCardRuleList);
          });
        } else {
          this.timesCardRuleList = [];
        }
      }
    },
    handleChooseTimesCardRule(item) {
      // console.log(item);
      if (item.checked) {
        this.$set(item, 'checked', false);
        this.timesCardPersonRechargeList = [];
        this.selectTimesCardRuleList = [];
      } else {
        for (let i = 0; i < this.selectTimesCardRuleList.length; i++) {
          this.$set(this.selectTimesCardRuleList[i], 'checked', false);
        }
        this.timesCardPersonRechargeList = [];
        this.selectTimesCardRuleList = [];
        this.selectTimesCardRuleList.push(item);
        this.$set(item, 'checked', true);
        this.handleAfterTimesCardPerson(item);
      }
    },
    handleAfterTimesCardPerson(selectedItem) {
      // console.log(selectedItem);
      const data = this.timesCardPersonList.find(
        (item) => item.kartClassifyId === selectedItem.kartClassifyId
      );
      if (data) {
        const newData = JSON.parse(JSON.stringify(data));
        newData.count += selectedItem.count;
        this.timesCardPersonRechargeList.push(newData);
        // console.log(111);
      } else {
        const newData = {
          timesCardPersonId: 1,
          kartClassifyName: selectedItem.kartClassifyName,
          count: selectedItem.count
        };
        this.timesCardPersonRechargeList.push(newData);
        // console.log(222);
      }
    },
    handleSearch(e) {
      this.currentMember = {};
      this.timesCardRuleList = [];
      this.timesCardPersonRechargeList = [];
      this.timesCardPersonList = [];
      this.selectTimesCardRuleList = [];
      const searchText = e.target.value;
      if (searchText.length < 8) {
        return;
      }

      this.$store.commit('setDisableLoading', true);
      getByCodeOrMobile(searchText).then((data) => {
        this.currentMember = data || {};
        if (this.currentMember.memberId) {
          console.log(this.currentMember.memberId);
          timesCardPersonList(this.currentMember.memberId).then((data1) => {
            this.timesCardPersonList = data1;
          });
        } else {
          this.timesCardPersonList = [];
        }
      });
    },

    // 创建充值订单
    handleSubmit() {
      if (this.selectTimesCardRuleList != null && this.selectTimesCardRuleList.length > 0) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.handleSave();
          } else {
            this.$message.error('请按要求填写');
            return false;
          }
        });
      } else {
        this.$message.error('请选择充值规则');
        return false;
      }
    },
    // 提交支付订单
    handleSave() {
      console.log(this.selectTimesCardRuleList[0]);
      this.form.amount = this.selectTimesCardRuleList[0].price;
      const params = {
        memberId: this.currentMember.memberId,
        // amount: this.form.amount,
        goodsName: `${this.selectTimesCardRuleList[0].kartClassifyName}_${this.selectTimesCardRuleList[0].timesCardName}`,
        kartClassifyId: this.selectTimesCardRuleList[0].kartClassifyId,
        count: this.selectTimesCardRuleList[0].count,
        amount: Number(this.form.amount),
        amountPay: Number(this.form.amount), // 应付金额
        mobile: this.currentMember.mobile,
        remarks: this.form.remarks,
      };
      console.log(params);
      timesCardRechargeSubmit(params)
        .then((data) => {
          this.formCopy = deepClone(this.form);
          this.form = data;
          console.log(this.form);
          this.handlePay();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 收款
    handlePay() {
      this.payVisible = true;
    },
    // 支付成功
    handlePayOk() {
      if (this.paramsId) {
        this.getData(this.paramsId);
      } else {
        this.handleBack();
      }
    },
    // 取消支付
    handleCancel() {
      if (!this.paramsId) {
        this.form = this.formCopy;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.aaa {
  width: 92px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: 8px;
  right: 5px;
}

.bbb {
  background-color: #409dff;
  text-align: center;
  font-size: 13px;
  font-weight: 450;

  color: #fff;
  word-spacing: -5px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 0;
  left: 8px;
  top: 15px;
  width: 120px;
}

.ccc {
  color: #fff;
  background-color: #409dff;
  font-size: 13px;
  height: 22px;
  line-height: 20px;
  margin-right: 10px;
  display: inline-block;
  padding: 0 6px;
  border-radius: 100px;
}
.couponCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  max-height: 400px;
  overflow: auto;
}
.couponCardItem {
  flex-shrink: 0;
  min-width: 200px;
  margin-right: 20px;
  height: 100px;
  display: flex;
  flex-direction: row;
  position: relative;
  border: 1px solid #ae6fda;
  border-radius: 10px;
  margin-bottom: 10px;
  .bbb {
    background: #ae6fda;
  }
  .left {
    border-radius: 7px 0 0 7px;
    width: 100px;
    background: #ae6fda;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .l-inner {
      text-align: center;
      .l1 {
        span {
          font-size: 39px;
        }
      }
    }
  }
  .right {
    padding: 10px;
    position: relative;
    flex: 1;
    .Title_name {
      font-size: 16px;
      line-height: 24px;
    }
    .indate {
      color: #adadad;
      font-size: 13px;
      margin-top: 6px;
    }
    .isShare {
      position: absolute;
      line-height: 35px;
      bottom: 0;
    }
  }
  .float-text {
    position: absolute;
    right: -6px;
    top: -8px;
  }
  .checked-ouline {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 34px;
    color: #ae6fda;
  }
}
.type-1 {
  border: 2px solid #ae6fda;
  .left {
    background: #ae6fda;
  }
  .bbb {
    background: #ae6fda;
  }
  .ccc {
    background: #ae6fda;
  }
  .checked-ouline {
    color: #ae6fda;
  }
}
.type-2 {
  border: 2px solid #ff6633;
  .left {
    background: #ff6633;
  }
  .bbb {
    background: #ff6633;
  }
  .ccc {
    background: #ff6633;
  }
  .checked-ouline {
    color: #ff6633;
  }
}
.type-3 {
  border: 2px solid #24c5c0;
  .left {
    background: #24c5c0;
  }
  .bbb {
    background: #24c5c0;
  }
  .ccc {
    background: #24c5c0;
  }
  .checked-ouline {
    color: #24c5c0;
  }
}
.type-4 {
  border: 2px solid #409dff;
  .left {
    background: #409dff;
  }
  .bbb {
    background: #409dff;
  }
  .checked-ouline {
    color: #409dff;
  }
}
.type-5 {
  border: 2px solid #409dff;
  .left {
    background: #ffd306;
  }
  .bbb {
    background: #ffd306;
  }
  .checked-ouline {
    color: #409dff;
  }
}
.is-used {
  background: #cfcfcf;
  border: 2px solid #cfcfcf;
  .left {
    background: #959595;
  }
  .bbb {
    background: #959595;
  }
}

.goods__list {
  display: flex;
  flex-flow: row wrap;
  max-height: 358px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.goods__item {
  display: flex;
  flex: 0 1 auto;
  position: relative;
  margin: 5px;
  padding: 3px 3px 0;
  text-align: center;
  //border: 1px solid @border-color-base;
  cursor: pointer;
  user-select: none;

  .goods__item__tips {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    line-height: 20px;
    color: #fff;
    background: rgba(24, 144, 255, 0.5);
  }

  &:hover {
    .goods__item__tips {
      display: block;
    }
  }
}

.goods__table {
  margin-top: 10px;
  /*border: 1px solid red;*/
  /*max-height: 260px;*/
}

.goods__item--title,
.goods__item--text {
  width: 74px;
  word-break: break-all;
  font-size: 12px;
  margin: 5px 0;
  user-select: none;

  white-space: normal;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.goods__item--title {
  font-size: 18px;
}
</style>
